@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* You can add global styles to this file, and also import other style files */
$body-background:rgb(215,215,215);//#D7D7D7;
$white-color:rgb(255,255,255);//#ffffff;
$black-color: rgb(0,0,0);//#000;
$cool-grey-color: rgb(140,146,172);//#8c92ac;
$primary-color:rgb(255,135,0);// #FF8700;
$dark-blue: rgb(39,68,99);//#274463;
$secondary-cobalt: rgb(0,71,186);//#0047BA;
$alumni-color: rgb(170,175,185);//#AAAFB9;
$blue-secondary: rgb(0,71,186);//#015486;
$mockup-blue-color: rgb(1,84,134);//#738700;
$pdf-icon-color: rgb(1,84,134);//#ff0000;
$excel-icon-color:rgb(0,136,0);//#008800;
$icon-disable-color: rgb(123,166,192);//#7ba6c0;
$disclaimer-color: rgb(204,51,0);//#CC3300;

/** Color palette's  for the site */
$WHITE	: rgb(255,255,255);//#FFFFFF;
$SILVER	: rgb(192, 192, 192);//#C0C0C0;
$GRAY	: rgb(128, 128, 128);//#808080;
$BLACK	: rgb(0,0,0);//#000000;
$RED	: rgb(255, 0, 0);//#FF0000;
$MAROON	: rgb(128, 0, 0);//#800000;
$YELLOW	: rgb(255, 255, 0);// #FFFF00;
$OLIVE	: rgb(128, 128, 0);//#808000;
$LIME	: rgb(0, 255, 0);//#00FF00;
$GREEN	: rgb(0,128,0);//#008000;
$AQUA	: rgb(0,255,255);//#00FFFF;
$TEAL	: rgb(0,128,128);///#008080;
$BLUE	: rgb(0,0,255);//#0000FF;
$NAVY	: rgb(0,0,128);//#000080;
$FUCHSIA :rgb(255,0,255);//#FF00FF;
$PURPLE : rgb(39, 34, 39);// #800080;

// $primary-site-color: purple;
$primary-site-color: rgb(55, 81, 106); // This is the default color of the Application
$secondary-site-color: $WHITE; // This can be used as font or background color, wherever required.
$input-error-color: $RED; //red; // This can be used for error validation color, whereever required
$input-font-color: rgb(148,148,148);// This can be used as font color, wherever required.
$input-border-color: rgb(55,59,61); // This can be used as border color, wherever required.
$box-shadow-color:rgb(140,146,172);
$anchor-color:rgb(0,0,0);
$searchbox-border-color: rgb(0,0,0); // black; // This can be used as border or font color.
$searchresult-border-color: lightblue;
$searchbox-result-hover-color: lightgray;
$password-validate-color: #AE1409;
$userprofile-home-navigation: #0056B3;
$loader-background-color:  rgba(0, 0, 0, 0.302);
$footer-content-hover-color: rgb(255,135,0);
$portlet-category-border-bottom-color :rgb(222,219,219); 
$carousel-border: rgb(204,204,204);
$carousel-title: rgb(51,51,51);
$carousel-indicators: rgb(140,146,172);
$header-border-bottom: rgb(218,218,218);
$header-popup-border: rgb(214,215,216);
$header-login-text: rgb(33,37,41);
$breadcrumb-color: #0056B3;  
$tile-background-color:  $WHITE; // white;
$primary-font-family:Open Sans, sans-serif; 
$categorydetailed-seprator-color: rgb(204,204,204);
$form-required-symbol-color: rgb(255,135,0);
$portlet-read-more-color: $primary-site-color;
$portlet-read-more-hover-color: rgb(222,159,2);
$portlet-box-shadow-color-one: rgba(43,47,51,0.08);//#2b2f3314; 
$portlet-box-shadow-color-two: rgba(73,80,87,0.05);//#4950570d;
$tabs-menu-background-color: rgb(215,215,215);
$line-divider-color: rgb(206,212,218);
$disclaimer-sign-in-popup-title-font-size: 1.25rem;
$primary-font-family: Open Sans, sans-serif;
$add-button-font-size: 13px;
$add-button-background-color: $primary-site-color;
$save-button-background-color: $primary-site-color;
$save-button-font-size: 1rem;

// Welcome portlet starts from here
$welcome-portlet-border-color: $carousel-border;
$welcome-portlet-title-color: $carousel-title;
$welcome-portlet-carousel-indicator: $carousel-indicators;
$welcome-portlet-carousel-header-border: $primary-site-color;
$welcome-portlet-carousel-indicator-active: $primary-site-color;
$welcome-portlet-carousel-navigation: $primary-site-color;

$welcome-portlet-header-title-font-family:Open Sans, sans-serif; 
$welcome-portlet-carousel-message-font-family:Open Sans, sans-serif; 

// Media queries - Desktop
$welcome-portlet-header-title-font-size-desktop: 1.5rem;
$welcome-portlet-carousel-indicator-font-size-desktop: 40px;
$welcome-portlet-carousel-message-font-size-desktop: 1rem;

// Media queries - Tabs
$welcome-portlet-header-title-font-size-large-phone: 1.25rem;
$welcome-portlet-carousel-message-font-size-large-phone: 0.85rem;

// Media queries - Small Tabs and Medium phones
$welcome-portlet-header-title-font-size-mid-phone: 1rem;
$welcome-portlet-carousel-message-font-size-mid-phone: 0.75rem;

// Media queries - Small phones
$welcome-portlet-header-title-font-size-small-phone: 0.85rem;
$welcome-portlet-carousel-indicator-font-size-small-phone: 1.875rem;
$welcome-portlet-carousel-message-font-size-small-phone: 0.7rem;
// Welcome portlet ends here 

/*Login component colors starts here*/
$login-panel-input-validation-color: $RED;
$login-panel-input-font-color: $input-font-color;
$login-panel-input-border-color:$input-border-color;
/*Login component colors ends here*/

/*FAQ component colors starts here*/
$faq-portlet-box-shadow-color: $box-shadow-color;
$faq-portlet-icon-color: $primary-site-color;
$faq-portlet-anchor-tag-color: $BLACK;

$faq-tile-name-font-family:Open Sans, sans-serif; 

$faq-icon-font-size: 30px;
$faq-tile-name-font-size: 1rem;

// Media queries - Small Tabs and Medium phone
$faq-icon-font-size-mid-phone: 0.8rem;
$faq-tile-name-font-size-mid-phone: 0.625rem;
/*FAQ component colors ends here*/

/*User help component colors starts here*/
$user-help-portlet-background-color: $WHITE;
$user-help-portlet-box-shadow-color: $box-shadow-color;
$user-help-portlet-icon-color: $primary-site-color;
$user-help-portlet-anchor-tag-color: $BLACK;

$user-help-name-font-family:Open Sans, sans-serif; 

$user-help-icon-font-size: 30px;
$user-help-tile-name-font-size: 1rem;

// Media queries - Small Tabs and Medium phone
$user-help-icon-font-size-mid-phone: 0.8rem;
$user-help-tile-name-font-size-mid-phone: 0.625rem;
/*User help component colors ends here*/

/*Footer component colors starts here*/
$footer-divider-border-color:$secondary-site-color;
$footer-background-color: $primary-site-color;
$footer-anchor-tag-onhover-color: $footer-content-hover-color;
$footer-anchor-tag-color:$secondary-site-color;

$footer-links-font-family:Open Sans, sans-serif; 
$footer-modal-print-button-font-family:Open Sans, sans-serif; 
$footer-eula-modal-header-font-family:Open Sans, sans-serif; 
$footer-contactUs-modal-header-font-family:Open Sans, sans-serif; 
$footer-contactUs-modal-content-header-font-family:Open Sans, sans-serif; 
$footer-security-policy-modal-header-font-family:Open Sans, sans-serif; 
$footer-security-disclaimer-modal-header-font-family:Open Sans, sans-serif; 
$footer-security-disclaimer-modal-content-font-family:Open Sans, sans-serif; 

$footer-links-font-size-all-except-small-phone: 0.8rem;
$footer-modal-print-button-font-size: 1rem;
$footer-eula-modal-header-font-size: 1rem;
$footer-contactUs-modal-header-font-size: 1rem;
$footer-contactUs-modal-content-header-font-size: 1rem;
$footer-security-policy-modal-header-font-size: 1rem;
$footer-security-disclaimer-modal-header-font-size: 1rem;


// Media queries - Small Tabs and Medium phone
$footer-modal-print-button-font-size-mid-phone: 0.6rem;

// Media queries - Small phone
$footer-links-font-size-small-phone: 0.39rem;
/*Footer component colors ends here*/

/*Header component colors starts here*/
$header-border-bottom-color:$header-border-bottom;
$header-font-color:$secondary-site-color;
$header-background-color: $primary-site-color;
$header-login-background-color:$secondary-site-color;
$header-border-color:$secondary-site-color;
$header-popup-border-color:$header-popup-border;
$header-login-text-color:$header-login-text;
$header-anchor-tag-font-color:$secondary-site-color;

$header-clientName-font-family:Open Sans, sans-serif; 
$header-userName-font-family:Open Sans, sans-serif; 
$header-login-font-family:Open Sans, sans-serif; 
$header-forgot-password-font-family:Open Sans, sans-serif; 
$header-self-registration-font-family:Open Sans, sans-serif; 
$header-logout-font-family:Open Sans, sans-serif; 

$header-forgot-password-font-size: 12px;
$header-self-registration-font-size: 12px;
$header-logout-font-size: smaller;

// Media queries - Desktop
$header-clientName-font-size-desktop: 1.5rem;
$header-userName-font-size-desktop: 1rem;
$header-login-font-size-desktop: large;

// Media queries - Tabs & Large phone
$header-clientName-font-size-large-phone: larger;
$header-login-font-size-large-phone: inherit;
$header-userName-links-logout-font-size-large-phone: smaller;

// Media queries - Small Tabs and Medium phone
$header-clientName-font-size-mid-phone: large;

// Media queries - Small phone
$header-clientName-font-size-small-phone: 0.72rem;
$header-login-font-size-small-phone: smaller;
$header-userName-links-logout-font-size-small-phone: 0.38rem;
/*Header component colors ends here*/

// Category information page starts from here
$category-portlet-navbar-font-color: $secondary-site-color;
$category-portlet-searchbox-border-color:$BLACK;
$category-portlet-categorylist-anchortag-color: $primary-site-color;
$category-portlet-category-searchresult-bg-color: $secondary-site-color;
$category-portlet-category-searchresult-border-color: $searchresult-border-color;
$category-portlet-searchresult-hover-color: $searchbox-result-hover-color;
$category-page-breadcrumb-color:$breadcrumb-color;

$category-page-breadcrumb-font-family:Open Sans, sans-serif; 
$category-page-name-heading-font-family:Open Sans, sans-serif; 
$category-page-searchbox-input-font-family:Open Sans, sans-serif; 
$category-searchresult-font-family:Open Sans, sans-serif; 
$category-page-categories-heading-font-family:Open Sans, sans-serif; 
$category-page-text-font-family:Open Sans, sans-serif; 
$category-page-category-name-heading-font-family:Open Sans, sans-serif; 
$category-page-description-font-family:Open Sans, sans-serif; 
$category-page-navbar-heading-font-family:Open Sans, sans-serif; 
$category-page-navbar-category-name-font-family:Open Sans, sans-serif; 
$category-page-pagination-font-family:Open Sans, sans-serif; 
$category-page-side-nav-description-font-family:Open Sans, sans-serif; 

$category-portlet-navbar-font-size:0.9rem;
$category-list-page-description-font-size:0.95rem;
$category-searchresult-font-size: small;
$category-page-navbar-font-size:0.9rem;
$category-page-description-font-size:0.95rem;
$category-page-searchbox-input-font-size:1rem;
$category-page-breadcrumb-font-size:1rem;
$category-page-navbar-heading-font-size:1.25rem;
$category-page-heading-font-size:1.25rem;
$category-page-name-heading-font-size:1.25rem;
$categories-page-box-panel-font-size:1rem;
$category-page-category-name-heading-font-size:1rem;
$category-page-pagination-font-size:1rem;

/*Media queries for small phones*/
$category-page-heading-small-devices-font-size:0.95rem;
$category-page-description-small-devices-font-size:0.6rem;
$category-page-text-small-devices-font-size:0.7rem;
$category-page-navbar-link-small-devices-font-size:0.7rem;

/*Media queries for very small phones*/
$category-page-name-heading-xsmall-devices-font-size:0.9rem;
$category-page-navbar-heading-xsmall-devices-font-size:0.65rem;
$category-page-heading-xsmall-devices-font-size:0.65rem;
$category-page-second-heading-xsmall-devices-font-size:0.9rem;
$category-page-description-xsmall-devices-font-size:0.35rem;
$category-page-text-xsmall-devices-font-size:0.4rem;
$category-page-navbar-link-xsmall-devices-font-size:0.5rem;
$category-page-searchbox-input-xsmall-devices-font-size: 12px;
$category-page-breadcrumbs-xsmall-devices-font-size: 0.75rem;
$category-page-search-result-list-xsmall-devices-font-size: x-small;
$category-page-navbar-xsmall-devices-font-size: 0.6rem;
$category-page-panel-category-name-xsmall-devices-font-size:0.4rem;
// Category information page ends here

// Category detailed information page starts from here
$categorydetailed-portlet-navbar-font-color: $secondary-site-color;
$categorydetailed-portlet-searchresult-bg-color: $secondary-site-color;
$categorydetailed-portlet-searchresult-border-color: $searchresult-border-color;
$categorydetailed-portlet-searchresult-hover-color: $searchbox-result-hover-color;
$categorydetailed-portlet-searchbox-border-color: $BLACK;
$categorydetailed-page-breadcrumb-color:$breadcrumb-color;
$categorydetailed-seperator-border-color: $categorydetailed-seprator-color;
$categordetailed-information-description-font-color:#000;

$categorydetailed-page-breadcrumb-font-family:Open Sans, sans-serif; 
$categorydetailed-page-name-heading-font-family:Open Sans, sans-serif; 
$categorydetailed-page-searchbox-input-font-family:Open Sans, sans-serif; 
$categorydetailed-searchresult-font-family:Open Sans, sans-serif; 
$categorydetailed-page-categories-heading-font-family:Open Sans, sans-serif; 
$categorydetailed-page-text-font-family:Open Sans, sans-serif; 
$categorydetailed-page-category-name-heading-font-family:Open Sans, sans-serif; 
$categorydetailed-page-description-font-family:Open Sans, sans-serif; 
$categorydetailed-page-navbar-heading-font-family:Open Sans, sans-serif; 
$categorydetailed-information-font-family:Open Sans, sans-serif; 
$categorydetailed-page-pagination-font-family:Open Sans, sans-serif; 
$categorydetailed-page-navbar-category-name-font-family:Open Sans, sans-serif; 
$categorydetailed-Faqquestion-font-family:Open Sans, sans-serif; 
$categorydetailed-left-nav-bar-categories-list-font-family:Open Sans, sans-serif; 
$categordetailed-information-item-font-family:Open Sans, sans-serif; 
$categordetailed-information-description-font-family:Open Sans, sans-serif; 

$categorydetailed-portlet-navbar-font-size:0.9rem;
$categorydetailed-searchresult-font-size: small;
$categorydetailed-description-font-size:0.95rem;
$categorydetailed-left-navbar-font-size:0.9rem;
$categorydetailed-page-searchbox-input-font-size:1rem;
$categorydetailed-page-pagination-font-size: 1rem;
$categordetailed-information-description-font-size:0.95rem;

// Media queries - Desktop
$categorydetailed-breadcrumbs-font-size-desktop:1rem;
$categorydetailed-navbar-heading-font-size-desktop:1.25rem;
$categorydetailed-pagename-heading-font-size-desktop:1.5rem;
$categorydetailed-categoryname-font-size-desktop:1.25rem;
$categorydetailed-Faqquestion-font-size-desktop:1rem;
$categorydetailed-information-font-size-desktop:0.95rem;
$categordetailed-information-item-font-size-desktop: 0.9rem;

// Media queries - Tabs
$categorydetailed-navbar-heading-font-size-large-phone:1rem;
$categorydetailed-navbar-categorieslist-font-size-large-phone:0.7rem;
$categorydetailed-categoryname-font-size-large-phone:1.25rem;
$categordetailed-information-item-font-size-large-phone:0.7rem;

// Media queries - Small Tabs and medium phone
$categorydetailed-navbar-heading-font-size-mid-phone:0.65rem;
$categorydetailed-pagename-heading-font-size-mid-phone:0.9rem;
$categorydetailed-navbar-category-font-size-mid-phone:0.5rem;
$categorydetailed-breadcrumbs-font-size-mid-phone:0.65rem;
$categorydetailed-categorydesc-font-size-mid-phone:0.65rem;
$categorydetailed-searchbox-font-size-mid-phone:12px;
$categorydetailed-searchresult-font-size-mid-phone:x-small;
$categorydetailed-categoryname-font-size-mid-phone:small;
$categorydetailed-result-desc-font-size-mid-phone:0.65rem;
$categorydetailed-navbar-linkdesc-font-size-mid-phone: 0.6rem;
$categordetailed-information-item-font-size-mid-phone:0.5rem;
$categordetailed-information-description-font-size-mid-phone:0.75rem;

// Media queries - Small phone
$categorydetailed-page-panel-category-name-xsmall-devices-font-size:0.4rem;
$categorydetailed-page-navbar-heading-xsmall-devices-font-size:0.65rem;
$categorydetailed-page-name-heading-xsmall-devices-font-size:0.9rem;
// Category detailed information page ends here

// Activate user page starts from here
$activateuser-border-color : $primary-site-color;
$activateuser-gohome-link-color: #0056B3;
$activateuser-validation-font-size:0.75rem;
$activateuser-submit-button-font-size: 1rem;

$activateuser-validation-font-family:Open Sans, sans-serif; 
$activateuser-submit-button-font-family:Open Sans, sans-serif; 
// Activate user page ends from here

// Forgot password page starts from here
$forgotpwd-border-color : $primary-site-color;
$forgotpwd-gohome-link-color: #0056B3;
$forgotpwd-validation-font-size:0.75rem;
$forgotpwd-submit-button-font-size: 1rem;
$forgotpwd-gohome-link-font-size:0.75rem;

$forgotpwd-validation-font-family:Open Sans, sans-serif; 
$forgotpwd-submit-button-font-family:Open Sans, sans-serif; 
$forgotpwd-gohome-link-font-family:Open Sans, sans-serif; 
// Forgot password page ends here

// Change password page starts from here
$changepwd-border-color : $primary-site-color;
$changepassword-savebutton-font-family:Open Sans, sans-serif; 
// Change password page ends here

// Reset password page starts from here
$resetpwd-border-color: $primary-site-color;
$resetpwd-error-color: $password-validate-color;
$resetpwd-error-validation-font-size: 13px;

$resetpwd-error-validation-font-family:Open Sans, sans-serif; 

// Media queries - Tabs
$resetpwd-re-enter-validation-large-device-font-size: 95%;

// Media queries - Small phone
$resetpwd-re-enter-validation-small-device-font-size: 65%;
// Reset password page ends here

//Validate security questions page starts from here
$validatesecques-box-panel-border-color : $primary-site-color;
$validatesecques-heading-font-size : 20px;

// Media queries - Tabs
$validatesecques-question-answer-field-large-devices-font-size : inherit;

// Media queries - Small phone
$validatesecques-heading-small-devices-font-size : 0.8rem;
$validatesecques-input-small-devices-font-size : x-small !important;
$validatesecques-validation-error-small-devices-font-size : 65%;
$validatesecques-button-small-devices-font-size : 0.7rem;
$validatesecques-heading-small-device-font-size: 0.8rem;
// Validate security questions page ends here

//Reset security questions page starts from here
$resetsecques-border-color : $primary-site-color;
$resetsecques-heading-font-family:Open Sans,sans-serif; 
$resetsecques-heading-font-size : 20px;
$resetsecques-button-font-size : 1rem;
$resetsecques-button-font-family : Open Sans,sans-serif;
// Media queries - Tabs
$resetsecques-input-large-devices-font-size : inherit;

// Media queries - Small Tabs and medium phone
$resetsecques-heading-small-devices-font-size : 0.8rem;
$resetsecques-input-small-devices-font-size : x-small !important;
$resetsecques-validation-error-small-devices-font-size : 65%;
$resetsecques-button-small-devices-font-size : 0.75rem;
// Reset security questions page ends here


//Userprofile page starts from here
$userprofile-breadcumb-navigation-color:$userprofile-home-navigation;
$userprofile-password-error-color: $password-validate-color;
$userprofile-error-validation-font-size: 14px;

$userprofile-error-validation-font-family:Open Sans, sans-serif; 
$userprofile-breadcrumbs-font-family:Open Sans, sans-serif; 

// Media queries - Desktop
$userprofile-breadcrumbs-font-size-desktop:1rem;

// Media queries - Small phone
$userprofile-panel-small-device-font-size: 0.5rem;
$userprofile-mat-form-field-small-device-font-size: 0.5rem;
$userprofile-max-charecters-hint-small-device-font-size: xx-small;

// Media queries - Very small phone
$mat-heading-xs-devices-font-size:x-small;
// Userprofile page ends here

/*sign-in-announcement styles starts here*/

$sign-in-announcement-notify-user:$primary-site-color;

$sign-in-announcement-title-font-size: 1.25rem;
$sign-in-announcement-body-font-size: 1rem;
$sign-in-announcement-button-font-size:1rem;
$sign-in-announcement-notify-user-font-size:smaller;

$sign-in-announcement-title-font-family: Open Sans, sans-serif;
$sign-in-announcement-body-font-family: Open Sans, sans-serif;
$sign-in-announcement-button-font-family: Open Sans, sans-serif;

// Media queries - Small phone
$sign-in-announcement-small-device-button-font-size:0.6rem;

/*sign-in-announcement styles ends here*/

/*Loader styles starts here*/
$progressloader-bg-color:$loader-background-color;
/*Loader styles ends here*/

/* Program Information portlet starts here */
$program-info-portlet-heading-background-color: $primary-site-color;
$program-info-portlet-heading-font-color: $secondary-site-color;

$program-info-portlet-header-font-family:Open Sans, sans-serif; 
$program-info-portlet-category-name-font-family:Open Sans, sans-serif; 
$program-info-portlet-category-description-font-family:Open Sans, sans-serif; 
$program-info-portlet-read-more-font-family:Open Sans, sans-serif; 
$program-info-portlet-header-font-size-tab: 0.8rem;

// Media queries - Desktop
$program-info-portlet-header-font-size-desktop: 1rem;
$program-info-portlet-category-name-font-size-desktop: 15px;
$program-info-portlet-category-description-font-size-desktop: 14px;
$program-info-portlet-read-more-font-size:0.9rem;

// Media queries - Tabs
$program-info-portlet-header-font-size-large-phone: 0.6rem;
$program-info-portlet-category-name-font-size-large-phone: 9px;
$program-info-portlet-category-description-font-size-large-phone: 8px;
$program-info-portlet-read-more-font-size-large-phone: 0.7rem;
$program-info-portlet-ribbon-icon-font-size-large-phone: xx-small;

// Media queries - Small Tabs and medium phone
$program-info-portlet-header-font-size-mid-phone: 0.3rem;
$program-info-portlet-category-name-font-size-mid-phone: 8px;
$program-info-portlet-category-description-font-size-mid-phone: 5px;
$program-info-portlet-read-more-font-size-mid-phone: 0.33rem;
$program-info-portlet-ribbon-icon-font-size-mid-phone: inherit;
/* Program Information portlet ends here */

/* Program Contacts portlet starts here */
$program-contacts-portlet-heading-background-color: $primary-site-color;
$program-contacts-portlet-heading-font-color: $secondary-site-color;

$program-contacts-portlet-header-font-family:Open Sans, sans-serif; 
$program-contacts-portlet-category-name-font-family:Open Sans, sans-serif; 
$program-contacts-portlet-category-description-font-family:Open Sans, sans-serif; 
$program-contacts-portlet-read-more-font-family:Open Sans, sans-serif; 
$program-contacts-portlet-header-font-size-tab: 0.8rem;

// Media queries - Desktop
$program-contacts-portlet-header-font-size-desktop: 1rem;
$program-contacts-portlet-category-name-font-size-desktop: 15px;
$program-contacts-portlet-category-description-font-size-desktop: 14px;
$program-contacts-portlet-read-more-font-size:0.9rem;

// Media queries - Tabs
$program-contacts-portlet-header-font-size-large-phone: 0.6rem;
$program-contacts-portlet-category-name-font-size-large-phone: 9px;
$program-contacts-portlet-category-description-font-size-large-phone: 8px;
$program-contacts-portlet-read-more-font-size-large-phone: 0.7rem;
$program-contacts-portlet-ribbon-icon-font-size-large-phone: inherit;

// Media queries - Small Tabs and medium phone
$program-contacts-portlet-header-font-size-mid-phone: 0.3rem;
$program-contacts-portlet-category-name-font-size-mid-phone: 8px;
$program-contacts-portlet-category-description-font-size-mid-phone: 5px;
$program-contacts-portlet-read-more-font-size-mid-phone: 0.33rem;
$program-contacts-portlet-ribbon-icon-font-size-mid-phone: inherit;
/* Program Contacts portlet ends here */

/* Timer Modal styles starts here*/
$timer-modal-header-font-family:Open Sans, sans-serif; 
$timer-modal-message-font-family:Open Sans, sans-serif; 
$timer-modal-button-font-family:Open Sans, sans-serif; 

$timer-modal-header-font-size: 1rem;
$timer-modal-message-font-size: 1rem;
$timer-modal-button-font-size: 1rem;
/* Timer Modal styles starts here*/

/* News portlet starts from here*/
$news-portlet-heading-background-color: $primary-site-color;
$news-portlet-heading-font-color: $secondary-site-color;

$news-portlet-header-font-family:Open Sans, sans-serif; 
$news-portlet-categoryname-font-family:Open Sans, sans-serif; 
$news-portlet-tileheader-font-family:Open Sans, sans-serif; 
$news-portlet-read-more-font-family:Open Sans, sans-serif; 
$news-portlet-tilename-font-size-tabs: 0.8rem;

// Media queries - Desktop
$news-portlet-header-font-size-desktop:14px;
$news-portlet-categoryname-font-size-desktop:15px;
$news-portlet-tileheader-font-size-desktop:16px;
$news-portlet-read-more-font-size:0.9rem;

// Media queries - Tabs
$news-portlet-tilename-font-size-large-phone: 0.6rem;
$news-portlet-readmore-font-size-large-phone:0.7rem;
$news-portlet-category-description-font-size-large-phone: 8px;
$news-portlet-category-name-font-size-large-phone:9px;
$news-portlet-ribbon-icon-font-size-large-phone: xx-small;

// Media queries - Small Tabs and medium phone
$news-portlet-tilename-font-size-mid-phone: 0.3rem;
$news-portlet-readmore-font-size-mid-phone:0.33rem;
$news-portlet-category-description-font-size-mid-phone: 5px;
$news-portlet-category-name-font-size-mid-phone:8px;
$news-portlet-ribbon-icon-font-size-mid-phone:inherit;
/* News portlet ends here*/

/*Priorauth portlet starts from here*/
$priorauth-heading-background-color: $primary-site-color;
$priorauth-heading-font-color: $secondary-site-color;

$priorauth-portlet-header-border-font-family:Open Sans, sans-serif; 
$priorauth-portlet-categoryname-font-family:Open Sans, sans-serif; 
$priorauth-portlet-tileheader-font-family:Open Sans, sans-serif; 
$priorauth-portlet-read-more-font-family:Open Sans, sans-serif; 
$priorauth-portlet-header-border-font-size:14px;
$priorauth-portlet-tilename-font-size-tabs: 0.8rem;

// Media queries - Desktop
$priorauth-portlet-categoryname-font-size-desktop:15px;
$priorauth-portlet-tileheader-font-size-desktop: 16px;
$priorauth-portlet-readmore-font-size:0.9rem;

// Media queries - Tabs
$priorauth-portlet-tilename-font-size-large-phone: 0.6rem;
$priorauth-portlet-readmore-font-size-large-phone:0.7rem;
$priorauth-portlet-category-description-font-size-large-phone: 8px;
$priorauth-portlet-category-name-font-size-large-phone:9px;
$priorauth-portlet-ribbon-icon-font-size-large-phone: xx-small;

// Media queries - Small Tabs and medium phone
$priorauth-portlet-tilename-font-size-mid-phone: 0.3rem;
$priorauth-portlet-readmore-font-size-mid-phone:0.33rem;
$priorauth-portlet-category-description-font-size-mid-phone: 3px;
$priorauth-portlet-category-name-font-size-mid-phone:5px;
$priorauth-portlet-ribbon-icon-font-size-mid-phone: inherit; 
/*Priorauth portlet ends here*/

/*Application links starts from here*/
$applinks-portlet-heading-background-color: $primary-site-color;
$applinks-portlet-heading-font-color: $secondary-site-color;

$applinks-portlet-header-font-family:Open Sans, sans-serif; 
$applinks-portlet-categoryname-font-family:Open Sans, sans-serif; 
$applinks-portlet-tileheader-font-family:Open Sans, sans-serif; 
$applinks-portlet-read-more-font-family: Open Sans, sans-serif;
$applinks-portlet-tilename-font-size-tabs: 0.8rem;
$applinks-portlet-tilename-font-size-tabs: 0.8rem;
$applinks-portlet-header-font-size:14px;

// Media queries - Desktop
$applinks-portlet-categoryname-font-size-desktop:15px;
$applinks-portlet-tileheader-font-size-desktop:16px;
$applinks-portlet-readmorelink-font-size:0.9rem;
// Media queries - Tabs
$applinks-portlet-readmorelink-font-size-large-phone:0.7rem;
$applinks-portlet-tilename-font-size-large-phone:0.8rem;
$applinks-portlet-category-description-font-size-large-phone:8px;
$applinks-portlet-category-name-font-size-large-phone:9px;
$applinks-portlet-ribbon-icon-font-size-large-phone: xx-small; 

// Media queries - Small Tabs and medium phone
$applinks-portlet-readmore-font-size-mid-phone:0.3rem;
$applinks-portlet-tilename-font-size-mid-phone: 0.33rem;
$applinks-portlet-category-description-font-size-mid-phone:5px;
$applinks-portlet-category-name-font-size-mid-phone:8px;
$applinks-portlet-ribbon-icon-font-size-mid-phone: inherit; 
/*Application links ends here*/

/*Login form starts from here*/
$login-page-font-family:Open Sans, sans-serif; 
$login-username-pwdfields-error-font-family:Open Sans, sans-serif; 
$login-forgotpwd-link-font-family:Open Sans, sans-serif; 
$login-rememberme-checkbox-font-family:Open Sans, sans-serif; 
$login-password-hint-font-family:Open Sans, sans-serif; 
$login-label-font-family:Open Sans, sans-serif; 
$login-page-font-size:16px;
$login-username-pwdfields-error-font-size:12px;
$login-rememberme-checkbox-font-size: 12px;

// Media queries - Desktop
$login-forgotpwd-link-font-size-desktop: 12px;
$login-password-hint-font-size-desktop:small;
$login-label-font-size-desktop:16px;
$login-savebutton-font-size-desktop:1rem;

// Media queries - Tabs
$login-password-hint-font-size-large-phone:x-small;
$login-forgotpwd-link-font-size-large-phone:smaller;
$login-label-font-size-large-phone:12px;

// Media queries - Small Tabs and medium phone
$login-password-hint-font-size-small-phone:0.45rem;
$login-forgotpwd-link-font-size-small-phone:x-small;
$login-username-pwdfields-width-font-size-small-phone: 9px;
$login-label-font-size-small-phone:7px;
/*Login form ends here*/

/*Change password starts from here*/

// Media queries - Desktop
$changepassword-savebutton-font-size-desktop:1rem;

// Media queries - Tabs
$changepassword-inputerror-fontsize-large-phone:95%;

// Media queries - Small phone
$changepassword-inputerror-fontsize-small-phone:65%;
$changepassword-savebutton-fontsize-small-phone:0.9rem;

/*Change password ends here*/

/*Registration starts from here*/
$registration-background-color:$primary-site-color;
$registration-font-color: $secondary-site-color;
$registration-seperator-line-color: $BLACK;
$registration-sucess-message-modal-color:$primary-site-color;

$registration-validate-button-font-family:Open Sans, sans-serif; 
$registration-seperator-font-family:Open Sans, sans-serif; 
$registration-heading-font-family:Open Sans, sans-serif; 
$registration-submit-button-font-family:Open Sans, sans-serif; 
$registration-breadcrumbs-font-family:Open Sans, sans-serif;
$provider-enrolment-page-heading-font-family:Open Sans, sans-serif; 
$provider-enrolment-note-font-family:Open Sans, sans-serif; 

$registration-matform-fields-fontsize-tabs: 0.7rem;
$provider-enrolment-page-heading-font-size:1.5rem;
$provider-enrolment-note-font-size: 1rem;

// Media queries - Desktop
$registration-breadcrumbs-font-size-desktop: 1rem;
$registration-validate-button-font-size-desktop:14.5px;
$registration-seperator-font-size-desktop:16px;
$registration-heading-font-size-desktop:24px;
$registration-submit-button-font-size:1rem;

// Media queries - Small Tabs and medium phone
$registration-validatebutton-font-size-mid-phone: 8px !important;
$registration-body-font-size-mid-phone:0.7rem;

// Media queries - Small phone
$registration-admin-rightpane-font-size-small-phone:0.5rem;
$registration-validatebutton-font-size-small-phone:6px !important;
$registration-matform-fields-fontsize-small-phone:0.5rem;
$registration-breadcrumb-font-size-small-phone: 0.5rem;
$registration-heading-tag-font-size-small-phone:0.6rem;
$provider-enrolment-note-font-size-small-phone: 0.5rem;

/*Registration ends  here*/

/*Scrollto top starts from here*/
$scrollto-top-button-content-font-family:Open Sans, sans-serif; 

// Media queries - Desktop
$scrollto-top-button-content-font-size-desktop:16px;

// Media queries - Tabs
$scrollto-top-button-content-font-size-large-phone:12px;
/*Scrollto top ends here*/

/*Unauthorized starts from here */
$unauthorized-heading-font-family:Open Sans, sans-serif; 
$unauthorized-content-font-family:Open Sans, sans-serif; 

$unauthorized-heading-font-size: 28px;
$unauthorized-content-font-size: 16px;
/*Unauthorized ends here */

/*Disclaimer starts from here*/
$disclaimer-title-font-family:Open Sans, sans-serif; 
$disclaimer-content-font-family:Open Sans, sans-serif; 
$disclaimer-save-button-font-family:Open Sans, sans-serif; 

$disclaimer-title-font-size:1.25rem;
$disclaimer-content-font-size:16px;

// Media queries - Desktop
$disclaimer-save-button-font-size-desktop:1rem;

// Media queries - Small Tabs and medium phone
$disclaimer-save-button-font-size-mid-phone:0.6rem;
/*Disclaimer ends here*/

/* Spinner inner Circle starts color */
$spinner-inner-circle-color: $primary-site-color;
/* Spinner inner Circle ends color */
/* Defining mixins for background colors */
@mixin tile-background {
    background: $primary-site-color;
    color: $secondary-site-color ; 
}
@mixin tile-dash-border {    
    .row {
		height: 10.5rem;
		font-size: 14px;
        overflow: hidden;
        margin: 0px;
        border-bottom: 1px dashed $portlet-category-border-bottom-color;    
        word-break: break-word;
	}
	li {
		font-size: 14px;
	}
}
@mixin  web-font-style {
    font-family: Open Sans, sans-serif;
}

body { 
    margin: 0;
    @include web-font-style;
    font-weight: 300;
    color: $BLACK;
    line-height: 1.6;
    background: transparent;
}


p > a {
    color: $breadcrumb-color;
  }

  .modal-body a {
    color: $breadcrumb-color;
  }

textarea{
    resize: none;
}

.mat-error {
    color: $password-validate-color
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label{
    color: $password-validate-color
}

.save-btn{
    button{
        background: $save-button-background-color;
        border-radius: 0rem;
        text-transform: uppercase;
        // @include web-font-style;
        font-family: Open Sans, sans-serif;
		font-size: $save-button-font-size;
    }
}

.add-btn{
    button{
        background: $add-button-background-color;
        border-radius: 0rem;
        text-transform: uppercase;
        color: $WHITE;
        // @include web-font-style;
        font-size: $add-button-font-size;
        font-weight: bold;
        font-family: Open Sans, sans-serif;
    }     
}

.hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.font-size-12, .font-form-control{
    font-size: 0.75rem;
    font-family: Open Sans, sans-serif;
}
.w-95 {
    width: 95%;
}

.blurry {
	filter: blur(20px);
}

.chk{
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
}

//Pop-Up styles
/**.button-round{
    border-radius: 1.5em !important;
    background-color: #800080;
    color: #ffffff;
    outline: none;
    width: 21%;
} */
// .modal-dialog{
//     width: 90%;
// }
.modal-header {
    @include tile-background;
    height: 3rem;
    padding-top: 0.5rem;
    border-radius: 0px;
    border: 0px;
    .close {
        opacity: initial;
        color: $WHITE;
    }
}
.modal-footer{
    border: none;
}
.modal-body {
    text-align: left;  
}
.modal-content {    
    border-radius: 0px;
    border: 0px;
}
.w-20 {
    width: 20%;
}

.form-required{
    color: $password-validate-color;
}
/** Drag and Drop Styling */
/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
  }
  /* high-performance display:none; helper */
  .gu-hide {
    left: -9999px !important;
  }
  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  /* added to the source element while its mirror is dragged */
  .gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
  }

  .sortCatTab{
      height: 370px;
      .move {
        cursor: move;
        tr{
            background-color: rgb(141, 138, 138);
        }
      }
  }
  .sortInfoTab{
      height:288px;
      .move {
        cursor: move;
        tr{
            background-color: rgb(141, 138, 138);
        }
      }
  }

  /* Public Facing Tile Anchors */
  .tile-anchor{
      a{
        color: $primary-site-color;
        text-decoration: none;
        font-weight: bold;
        font-size:0.9rem;
        &:hover {
            color: $portlet-read-more-hover-color;
            text-decoration: none;
            i {
                padding-left: 3px;
            }
        }
        i{
            transition: all 0.2s linear;
            padding-left: 6px;
            transform: scale(1.7,1.2);
        }
      }
  }

.table-color{
    @include tile-background;   
}
.mouse-over{
    cursor: pointer;
}
.spanColor {
    color: #0000FF;
}
.tile-box-shadow {
    box-shadow:0 4px 16px 0 $portlet-box-shadow-color-one, 0 0 12px 0 $portlet-box-shadow-color-two;
    .tile-flag i{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(180deg);
        transform: rotate(269deg);
        margin-right: 15px;
        font-size: 20px;
    }
}

.info-icon {
    color: $primary-site-color;
    font: normal normal normal 14px/1 FontAwesome;
}
.form-head-title {
    font-size: 1rem;
    font-weight: 600;
}
/** overwriting existing form label **/
.col-form-label {
    padding-top: 0px; 
}
.line-divider {
    border: 1px dashed $primary-site-color;
}

/*** Pagination Styles ****/
.pagination li{
    padding: 0 5px;
    //font-size: medium;    
    cursor: pointer;
    text-align: center;
    margin-right: 5px;    
    i {
        font-size: 20px;
        padding-top: 3px;
    }
    a {
        font-size: 1rem;
    }
    
}
.pagination li.active {        
    @include tile-background;  
}

/*** ngx editor css change ***/
.ngx-editor .ngx-wrapper .ngx-editor-textarea {
    b, strong {
        font-weight: bold;
    }
}

.ngx-editor .ngx-wrapper .ngx-editor-textarea::before {
    content: normal !important;
}

b, strong {
    font-weight: bold;
}

/** faq & user button **/
.tile-height {
    height: 5.5rem;
    a{
        h6 {
            padding: 0px 10px;
            word-break: break-all;
        }
    }
}

.outline {
    outline: none;
}
.f-1 {
    font-size: 1rem;
    font-family: Open Sans, sans-serif;
}

/** Tile row **/
.row-icon{
    width: 6%;
    text-align: center;
}
.row-text{
    width: 88%;
}
.row-chevron{
    width: 5%;
    text-align: center;
}

/** Date Picker Plugin Styles */
.owl-dt-timer{
    padding: 0 0 0.3rem 0;
    height: 4rem;
}
.owl-dt-control-button {
    font-size: 0.8rem;
}
.owl-dt-timer-content {
    margin: 0;
    .owl-dt-timer-input {
        font-size: 0.8rem;
        padding: 0;
    }
} 

//Ribbon structure
.nav-link {
    font-weight: bold;
}
.admin-left-pane {
    width: 18.5%;
}
.admin-right-pane {
    width: 100%;
    height: auto;
}
.admin-right-pane .content-top-menu {
    font-size: 15px;
}
.admin-right-pane .content-top-menu li a {
    @include tile-background;
    padding-left: 14px;
    padding-left: 14px;
    padding-right: 14px;
}
.admin-right-pane .content-top-menu li :hover {
    background-color: $primary-site-color;
    box-shadow: 0px 0px 25px $primary-site-color;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.12);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.12);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.12);
    transition: all 200ms ease-in;
    transform: scale(1.12);
}
.admin-right-pane .content-top-menu li .nav-link.active {
    background-color: $WHITE;
    color: $BLACK;
    z-index: 0;
    box-shadow: 0px 0px 25px #fff;
    -webkit-transition: none;
    -webkit-transform: none;
    -ms-transition: none;
    -ms-transform: none;   
    -moz-transition: none;
    -moz-transform: none;
    transition: none;
    transform: none;
}
.admin-right-pane .tab-content {
    background-color: #fff;
}

.bg-top-menu {
    background-color: $tabs-menu-background-color;
}
li.active {
    color: whitesmoke;
    background-color: $BLUE;
}

//BreadCrumb - Start
ul.breadCrumbList {
    padding: 10px 61px 0px;
    list-style: none;
}
ul.breadCrumbList li {
    display: inline;
    font-weight: bolder;
}
ul.breadCrumbList li+li:before {
    padding: 8px;
    color: black;
}
ul.breadCrumbList li a {
    color: $breadcrumb-color;
    text-decoration: none;
}
ul.breadCrumbList li a:hover {
    color: #01447e;
    text-decoration: underline;
}
.titleMsg{
    width: 9rem;
}
//BreadCrumb - End
/**  Material form fields Styles **/
mat-form-field.mat-form-field {
    font-size: 1rem;
    @include web-font-style();    
    color: warn; 
    line-height: 1.50;
    
}
.mat-option-text {
    overflow: auto !important;
    text-overflow: initial !important;
}
.mat-table th {
    @include tile-background;   
}
.mat-sort-header-stem, .mat-sort-header-arrow {
    color: $WHITE;
} 
textarea.mat-input-element {   
    resize: none !important;   
}


/** To display the title text for all portlets */
.title-txt-disp {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: clip;
    padding-top: 3px;
}
/** Upload Image Notification Icons Style **/
.success_tick {
    font-size: 18px;
    color: $LIME;
}

.error_cross {
    font-size: 18px;
    color: #a94442;
}
/** For disabling mat tables */
.mat-table-disable{
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
}
.wavelineht{
    line-height: 1.2;
} 
.wavefix{
    font-size: $disclaimer-sign-in-popup-title-font-size; 
 }
 .wavebreadcumbfix{
    font-size: 1rem;
    font-family: Open Sans, sans-serif; 
    }

 .overrideLinkStyles{
    color: $white-color !important;
    text-decoration: none;
 }
 .overrideRoleLinkStyles{
    color: $black-color !important;
 }
 .overrideRoleAnchorStyles:hover{
    text-decoration: none !important;
 }
 
 
/** IE 11 fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    /** IE 11 fixes */
    .mat-select-panel {
      min-width: inherit !important;
    }
    .cdk-overlay-pane {
      display: block;
    }
 }
hr.fine-line {
    border-top: 1px solid $line-divider-color;
}

/** Mobility issue fix for pop-up **/
// Small devices (landscape phones, less than 575px)
@media (max-width: 575.98px) {
    .modal-dialog{
        margin: 0rem 1.3rem;
    }
}